@import url("../../../Styles/sassStyles.css");

.talent-logo {
  position: relative;
}

.horizontal-line {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  /* height: 40px; */
  background-color: white;
}

.logout-tooltip {
  border: 1px solid rgb(124, 123, 123) !important;
  border-radius: 6.5px !important;
  padding: 4px !important;
  font-size: 12px !important;
  color: rgb(126, 118, 118) !important;
  z-index: 1;
}

/* .logout-tooltip .react-tooltip-arrow {
    border-left: 1px solid rgb(124, 123, 123) !important;
    border-top: 1px solid rgb(124, 123, 123) !important;
  } */

input[type="radio"] {
  accent-color: var(--primary-color);
}

.mod-btn-color {
  background-color: var(--primary-btn) !important;
  color: #ffff;
  border: 1px solid var(--primary-btn) !important;
  box-shadow: none !important;
}

.nav-bg {
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
  width: 100%;
}

.toggle-bth {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.search-container {
  display: flex;
}

.box {
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;
  padding: 4px;
}

.search-btn-1 {
  margin-left: -30px;
  margin-top: 7px;
}
.search-btn-2 {
  margin-left: -30px;
  background: none;
  color: var(--primary-color);
}

.search-box {
  width: 65%;
  text-align: right;
}

.suggestions-container {
  position: absolute;
  margin-top: 3%;
  /* Position the container just below the input */
  margin-left: 0%;
  width: 50%;
  /* Take full width of the parent */
  background-color: #ffffff;
  /* Background color */
  border-radius: 4px;
  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Shadow for a subtle effect */
  z-index: 1000;
  /* Ensure it's above other content */
  max-height: 400px;
  /* Maximum height for scrolling */
  overflow-y: auto;
  /* Enable vertical scrolling if needed */
  text-align: left;
}

.sub-nav-heading {
  font-size: 14px;
}

.suggestion-item {
  padding: 8px 15px;
  /* Padding for each suggestion item */
  cursor: pointer;
  /* Cursor style */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
  font-size: 14px;
  color: #777777;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
  /* Background color on hover */
}

@media (max-width: 768px) {
  .suggestions-container {
    max-height: 150px;
    /* Adjust maximum height for smaller screens */
  }
}

@media (min-width: 1700px) {
  .suggestions-container {
    margin-right: 2.5%;
  }
}

.help-button-navbar {
  background: none;

  border: none;
  margin: 10px;
}

.help-button-navbar:active {
  border: none;
}

.nav-logo-container {
  width: 10%;
  /* background-color: #777777; */
}

.navbar-icon-container {
  display: flex;
  width: 85%;
  align-items: center;
  justify-content: flex-end;

  gap: 10px;
}

@media only screen and (max-width: 1250px) {
  .nav-logo-container {
    width: 8%;
  }
  .navbar-icon-container {
    width: 88%;
  }

  .search-box {
    width: 55%;
  }
}

@media only screen and (max-width: 957px) {
  .nav-bg {
    flex-direction: column;
    text-align: start;
  }
  .search-box {
    width: 45%;
  }
}
