@import url('../../Styles/sassStyles.css');



.main-container {
    margin: 0;
    padding: 0;
    width: 100%;
    /* display: flex;
flex-direction: column; */
    /* background-color: black !important; */
    /* height: 100dvh; */
    height: 100% !important;
}

.admin-sidebar-section {
    width: 3%;
    background: var(--primary-shade1) 0% 0% no-repeat padding-box;
    min-height: max-content;
}


.admin-dashboard-main-container {
    background-color: #F7F7F7 !important;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.admin-dashboard-title {
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 500;
}

/* .admin-dashboard-dropdown {
    border: 1px solid #F0D4FF !important;
    background-color: #F8EBFF40 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    color: var(--primary-color) !important;
} */

.form-select {
    --bs-form-select-bg-img: url('../../assets/dropdown-arrow.svg') !important;
}

.admin-main-interview-status-container,
.admin-main-recruitment-data-container {

    display: flex;
    border-bottom: 1px solid #E2E2E2;
    gap: 20px;
    justify-content: space-between;


}

.admin-interview-status,
.admin-recruitement-data {
    display: flex;
    width: 20rem;
    height: 150px;
    justify-content: space-between;
    /* gap:10px; */
    background-color: #f6ebfe;
    box-shadow: 0px 3px 10px #DFC4FE33;
    border: 1px solid #DFC4FE;
    background-size: cover;
    border-radius: 10px;
    opacity: 1;
    color: #600097;

}


.admin-interview-status:nth-child(2) {

    background-color: #e0f6ff;
    box-shadow: 0px 3px 10px #7DCBFE33;
    border: 1px solid #7DCBFE;
    background-size: cover;
    color: #0088FF;

}


.admin-interview-status:nth-child(3) {
    background-color: #ffece0;
    box-shadow: 0px 3px 10px #FFAB6E33;
    border: 1px solid #FFAB6E;
    background-size: cover;
    color: #DF6800;

}

.admin-interview-status:nth-child(4) {
    background-color: #c8f9de;
    box-shadow: 0px 3px 10px #8FE9A533;
    border: 1px solid #8FE9A5;
    background-size: cover;
    color: #00C22D;

}

/* 
.admin-interview-status:nth-child(5){
    background: transparent url('../../assets/backTemp5.svg') 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 0px 10px #000000;
    border: 1px solid #000000;
    background-size: cover;
} */

.admin-interview-status:nth-child(5),
.admin-recruitement-data:last-child {
    background: transparent;
    box-shadow: none;
    border: none;
    background-size: cover;
    justify-content: end;
    align-items: center;
}

.admin-recruitement-data {
    background: transparent url('../../assets/recruitmentData.svg') 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: none;
    color: var(--primary-color);
}


.total-ai-review {
    margin-left: 23%;
}

.total-fraud-detection {
    margin-left: 5%;
}

.status-count-container,
.data-count-container {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.status-count {
    font-size: 53px;
    font-weight: 500;
}

.status-count-font {
    font-size: 48px;
    font-weight: 500;
}

.time-count {
    font-size: 20px;
    font-weight: 500;
}

.status-text {
    font-size: 17px;
    font-weight: 500;
}

.data-text {
    font-size: 17px;
    font-weight: 500;
}

.sidemenu-container {
    width: 10%;
}

.interview-status-title {
    text-align: right;
    color: #E2E2E2;
    font-size: 50px;
    font-weight: 800;
}

.admin-bottom-main-container {

    display: flex;
    justify-content: flex-start;
    gap: 20px;

}

.admin-recruitement-funnel-container {

    width: 33.3%;
}

.admin-top-job-orders-container {

    width: 33.3%;
}

.admin-recent-interview-container {

    width: 33.3%;
}

.recruit-funnel-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 500;
}

.admin-job-title {
    font-size: 18px;
    color: var(--primary-color);
    font-weight: 500;
    text-align: left;
}

.recruitment-chart-container,
.admin-top-job-order-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #0000001A;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    text-align: left;
    /* gap: 4em; */

}

.recruitment-chart-legend {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    gap: 7px;
    width: 50%;
}

.top-job-order-table {
    width: 100%;
    font-size: 14px;
    text-align: left;
}

.top-job-order-table tr td {
    padding: 6px;
    border-bottom: 1px solid #EEE6F3;
    margin-top: 10px;
    /* background-color: #0088FF; */
}

.top-job-order-table tr:last-child td {
    border-bottom: none;
    /* Hide bottom border for last row */
}

.applicant-td {
    text-align: right;
    font-weight: bold;
}

.funnel-chart {
    position: absolute;
    top: 10%;
    left: 41%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    font-weight: 500;
    line-height: 1.8;
}

.no-records-found {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
}

@media only screen and (max-width: 1306px) {

    .admin-main-interview-status-container,
    .admin-main-recruitment-data-container {
        gap: 10px;
        flex-wrap: nowrap;
    }

    .admin-interview-status,
    .admin-recruitement-data {
        width: 13rem;
        height: 100px;
    }

    .status-count {
        font-size: 38px;
        font-weight: 500;
    }


    .status-text,
    .data-text {
        font-size: 12px;
        padding-bottom: 2px;
    }
}

@media only screen and (max-width: 1040px) {

    .admin-main-interview-status-container,
    .admin-main-recruitment-data-container {
        gap: 10px;
        flex-wrap: nowrap;
    }

    .status-count {
        font-size: 35px;
        font-weight: 500;
    }

    .interview-status-title {
        font-size: 35px;
    }

    .data-count-container {
        margin-top: 10px;
        gap: 10px;
    }

}

@media only screen and (max-width: 992px) {

    .admin-main-interview-status-container,
    .admin-main-recruitment-data-container {
        gap: 10px;
        flex-wrap: nowrap;
    }

    .status-count {
        font-size: 30px;
        font-weight: 500;
    }

    .interview-status-title {
        font-size: 30px;
    }

    .data-count-container {
        margin-top: 10px;
        gap: 20px;
    }

    .data-text {
        font-size: 10px;
        padding-bottom: 2px;
    }

    .time-count {
        font-size: 10px;
    }
}


/* @media only screen and (max-width: 1500px){
    .admin-main-interview-status-container,
    .admin-main-recruitment-data-container{
        gap:20px;
        justify-content: flex-start;
    }
    .admin-interview-status:nth-child(5){
        margin-left:24rem;
    }
    .admin-recruitement-data:last-child{
        margin-left:22rem;
    }
  

    .admin-interview-status:nth-child(5),.admin-recruitement-data:last-child{
     
 display: flex;
justify-content: flex-end;
align-items: center;
width: fit-content;
background-color: dimgray;

    }

 .interview-status-title{
        font-size: 53px;
        text-align: right;
    }

    .data-text:last-child{
        font-size: 15px;
    }

    .admin-sidebar-section{
        width:3%;
    }

    .admin-dashboard-main-container{
        background-color: #F7F7F7 !important;
        width:97%;
    }
    
} */


@media only screen and (min-width: 1600px) {


    .admin-section-container {
        height: 100vh;
        gap: 0px;
    }
}



@media only screen and (max-width: 1508px) {

    .admin-main-interview-status-container,
    .admin-main-recruitment-data-container {
        justify-content: space-between;
    }

    .admin-interview-status,
    .admin-recruitement-data {
        width: 15rem;
        height: 110px;
        justify-content: space-between;
        margin-bottom: 2px;
    }

    .status-count {
        font-size: 38px;
    }

    .status-text {
        font-size: 15px;
    }

    .data-text {
        font-size: 15px;
    }

    .admin-bottom-main-container {
        gap: 20px;
    }

    .interview-status-title {
        font-size: 38px;
    }


    .admin-sidebar-section {
        width: 4%;
    }


    .admin-dashboard-main-container {
        background-color: #F7F7F7 !important;
        width: 97%;
        height: inherit;
        display: flex;
        flex-direction: column;

    }
}

.form-select option {
    border-radius: 10px;
}

.disabled-div {
    width: 15rem;
    height: 110px;
    justify-content: space-between;
    margin-bottom: 2px;
    opacity: .1;
}

.footer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    /* z-index:1; */
}

.admin-section-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    /* background-color: #0088FF; */
    /* height: 100vh; */
}

.recent-interview-td {
    width: 190px;
    /* Set a fixed width for the table cells */
    text-overflow: ellipsis;
    /* Truncate text with an ellipsis if it overflows */
    white-space: nowrap;
    /* Prevent wrapping of text */
    overflow: hidden;
    /* Hide any content that overflows the cell */
    /* display: inline-block; */
}

@media only screen and (min-width: 1600px) {
    .recent-interview-td {
        width: 300px;
    }
}

.funnel-chart-container {
    width: 60%;
    margin-top: 5px;
    margin-bottom: 10px;
}

.funnel-pipeline-chart .funnel-pipeline-chart-row .funnel-pipeline-chart-title {
    padding-bottom: 5px;
}

.funnel-pipeline-chart .funnel-pipeline-chart-row {
    cursor: pointer !important;
    color: black !important;
    font-weight: bold !important;
}

.funnel-pipeline-chart .funnel-pipeline-chart-row:last-child {
    padding-bottom: 13px !important;
}

.funnel-pipeline-chart .funnel-pipeline-chart-row {
    padding: 7px 0 !important;
    margin: 0px !important;
    /* min-height: 10px !important; */
}

.tour-alignment {
    text-align: left;
}

.tour-alignment-content {
    display: flex;
}

.tour-alignment-title {
    width: max-content;
    background-color: rgb(12, 61, 24)22D;
}