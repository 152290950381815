@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;800;900&display=swap);
@import url('../../Styles/sassStyles.css');

body {
  background: var(--primary-shade3) !important;
}

.container-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.jobs-head {
  text-align: center;
}

.font {
  font-weight: 500;
}

.search-btn {
  background-color: #e3c9f2 !important;
  border: 1px solid #e3c9f2 !important;
}

.table-wrap {
  margin-top: 15px;
}

.rdt_Table {
  background: #f8ebff40 0% 0% no-repeat padding-box !important;
  border: 1px solid #f0d4ff;
  border-radius: 10px;
  padding: 20px;
}

.rdt_TableBody {
  /* background: #F8EBFF40 0% 0% no-repeat padding-box !important; */
  border-top: 1px solid #60009780;
  /* text-align: center !important; */
  /* border-radius: 10px; */
}

.table-wrap {
  .brFloq {
    /* border-bottom: 1px solid #60009780 !important; */
    font-size: 16px !important;
    font-weight: 600 !important;
  }
}

.kqWIVd {
  border-top: none !important;
}

div[role="columnheader"] {
  font-size: 14.5px;
  font-weight: 500;
  /* cursor: pointer; */
  /* color: ; */
}

.brFloq,
.emBIby,
.cTRXdr {
  color: #11031a !important;
}

.ques-btn {
  background-color: var(--secondary-btn);
  border-radius: 4px;
  color: #ffff;
  /* margin-right: 20px; */
  /* height: 30px; */
  border: none;
  font-size: 14px;
  /* margin-right: 40px; */
}

.ques-btn:hover {
  background-color: var(--secondary-btn) !important;
}

input[type="date"]::-webkit-datetime-edit {
  display: none;
}

.canvas-question-label {
  color: #600097;
}

.schedule-btn img {
  font-family: "Roboto", sans-serif;
}

.link-input,
.question-select {
  border: 0.5px solid rgb(197, 196, 196) !important;
}

.offcanvas-body .form-select {
  background-color: #f7f7f7;
}

.custom-table-cell {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
}

.custom-cell-job-role {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  white-space: nowrap;
  cursor: pointer;
}

.custom-cursor {
  cursor: pointer;
}

.load-text {
  font-size: 1.1rem !important;
}

@media (min-width: 1800px) {
  .table-class {
    margin-inline: 200px !important;
  }
}

.job-list-label {
  color: var(--primary-color);
  font-size: 20px;
  font-weight: 600;
}


.job-csv-download {
  background: #F8EBFF 0% 0% no-repeat padding-box;
  border: 1px solid #E3C9F2;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
}

.job-csv-download:hover {
  background: #46006E 0% 0% no-repeat padding-box;
  border: 1px solid #46006E;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  font-size: 13px;
  color: white;
  cursor: pointer;
}

.skill-card-title {
  font-size: 20px;
  font-weight: 500;
}

.skill-input-div {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: auto;
  /* border: 2px solid #ccc !important;
  border-width: 2.5px !important; */
  /* height: 50px; */
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
}


.skill-input-ul {
  width: 100%;
  list-style-type: none;
  margin-top: 2px;
  gap: 20px;
  margin: 8px;
  display: flex;
  flex-wrap: wrap;
}

.skill-input-li {
  width: max-content;
  height: auto;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 3px;
  opacity: 1;
  text-align: center;
  color: #707070;
  font-size: 16px;
  padding: 5px;
}

.skill-input {
  border: none !important;
  width: 100%;
  height: auto;
  font-size: 14px !important;
  padding: 0px;
  margin: 8px;
  color: #707070;
  background-color: transparent;
}

.skills-required-title {
  font-size: 15px;
  font-weight: 500;
}



.joblist-details-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}