.login-body {
  background-color: #F8EBFF;
  height: 100vh;
}

.input-div {
  display: grid;
}

.footer-div {
  display: flex;
  justify-content: space-between;
}


.divider-login {
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.divider-login::before,
.divider-login::after {
  flex: 1;
  content: '';
  padding: 3px;
  background-color: #E9E9E9;
  margin: 5px;
}

.button-container-login {
  /* margin: 10px 5px 0px 5px; */
  text-align: center;
}

.azure-login {
  cursor: pointer;
}

.login-btn {
  width: 272px;
  height: 40px;
  /* UI Properties */
  background: #B59BD7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  border: none;
  color: #F7F7F7;
  font-size: 15px;
  margin-top: 6px;
}

.login-btn:hover{
  background: #977eb7 ;
}

.login-btn-azure {
  color: #36454F;
  font-size: 20px;
  width: 100%;
  height: 45px;
  margin-top: 10px;
  border: #A9A9A9;
  border-radius: 5px;
  background: #A9A9A9;
}


.basic-auth-login-card {
  width: 352px;
  /* padding: 30px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 20px;
}

.auth-login-card{
  width: 352px;
  height: 509px;
  /* padding: 30px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 20px;
}

.login-label {
  font-size: 16px;
  font-weight: bold;
}



.signin-label {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #600097;
  margin-top: 10px;
}

.login-logo {
  width: 220px;
  height: 85px;
  opacity: 1;
  margin-top: 10px;
}

.form-control-login {
  width: 272px;
  height: 40px;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #E9E9E9 !important;
  border-radius: 4px;
  opacity: 1;
}

.form-control-login-disabled {
  width: 272px;
  height: 40px;
  background: #a29898 0% 0% no-repeat padding-box;
  border: 1px solid #a39797 !important;
  border-radius: 4px;
  opacity: 0.4;
}

.basic-form-div {
  margin: 40px;
}

.forgot-div {
  width: 272px;
  display: flex;
  justify-content: flex-end;
}

.forgot-psw-label {
  color: #333333;
  margin-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}


.single-signon-div {
  width: 352px;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 12px 12px;
  opacity: 1;
  height: 114px;
  text-align: center;
}

.single-signon-btn {
  width: 272px;
  height: 40px;
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #C2C2C2;
  border-radius: 4px;
  opacity: 1;
  color: #5D5D5D;
  font-size: 15px;
  font-weight: bold;
}

.single-signon-btn:hover{
  background: #C2C2C2;
}

.btn-logo {
  width: 22px;
  height: 22px;
  opacity: 1;
  margin-right: 15px;
}

.single-signon-login-card{
  width: 352px;
  height: 258px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 12px;
  opacity: 1;
}

.forgot-password-sub {
  margin: 40px;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  margin-top: 30px;
}

.forgot-password-button-div {
  display: flex;
  justify-content: space-evenly;
  margin: 25px;
}


.forgot-submit-btn {
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  border: 1px solid var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  width: 80px;
  height: 32px;
}

.forgot-cancel-btn {
  background: #E9E9E9 0% 0% no-repeat padding-box;
  border: 1px solid #C2C2C2;
  border-radius: 4px;
  opacity: 1;
  width: 80px;
  height: 32px;
}