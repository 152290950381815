/* @import url('../../Styles/sassStyles.css'); */

.list-head {
    /* min-height: 87vh; */
    background-color: #fff;
    margin-top: 30px;
    border-radius: 15px;
}

.question-body {
    min-height: auto;
    border: 2px solid var(--primary-shade2);
    border-width: 3px;
    justify-content: start !important;
}

.hr-line-ques hr {
    border: 2px solid var(--primary-color);
    border-width: 2px;
}

.form-group input,
select,
textarea {
    border: 2px solid #ccc !important;
    border-width: 2.5px !important;
}

.form-group .ans-input {
    height: 25vh;
}

.form-group input,
.form-select select,
textarea:focus {
    outline: none !important;
}

.question-name {
    position: relative;
    width: 100%;
    z-index: 20;
    margin-bottom: 15px;
}

.questions textarea::placeholder,
input::placeholder {
    color: #707070 !important;
    font-weight: 500;
    outline: none;
}

.question-name input {
    background-color: #fff !important;
}

.question-input input {
    background-color: #fff !important;
}

.question-name input[type="text"] {
    text-align: left !important;
}

.question-input input[type="text"] {
    text-align: left !important;
}

.buttons button:focus:not(:focus-visible) {
    outline: none !important;
}

/*Duration CSS*/

.number {
    width: 20rem;

}

.number input {
    height: 3.5rem;
    background-color: #F7F7F7 !important;
    outline: none !important;
    border-radius: 1px;
}

.minus,
.plus {
    width: 5rem;
    height: 3.5rem;
    background: #E9E9E9;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #ddd;

    text-align: center;
    cursor: pointer;
}

.plus {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

/* .add-question {
    height: 46px;
    text-align: center;
    font-size: 26px;
    border: 1px solid #ddd;
    border-radius: 30px;
    display: inline-block;
    vertical-align: middle;
    background-color: #f7f7f7;
    color: #707070;
} */

.add-question::placeholder {
    font-size: 10px;
    width: 1px;
}

/* Media Query */

/* @media (max-width: 576px) {
    .list-head {
        height: auto;
    }
} */

sup {
    color: red;
}


.add-opt {
    margin-top: -150px !important;
    width: 20px !important;
    margin-right: 10px;
}

.add-job-select {
    height: 46px;
}

.quill {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
    opacity: 1;
}


.ql-container {
    /* border: 1px solid #ccc; */
    border-radius: 4px;

}

/* Customize the editor content area */
.question-modal-textbox .ql-editor {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    min-height: 150px !important;
    height: 150px !important;
    overflow-y: auto;
}

/* Customize the toolbar styles */
.ql-toolbar {
    border-bottom: 1px solid #ccc !important;
    border-radius: 4px;
    /* background-color: #f3f3f3; */
}

/* Customize the toolbar button styles */
.ql-toolbar button {
    color: #333;
}

/* Customize the active state of toolbar buttons */
.ql-toolbar button.ql-active {
    color: #fff;
    background-color: #007bff;
}

.ans-input-div {
    height: auto;
    border: 2px solid #ccc !important;
    border-width: 2.5px !important;
    /* height: 50px; */
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
}

.key-input {
    border: none !important;
    width: 500px;
    font-size: 14px !important;
    padding: 0px;
    margin: 8px;
    color: #707070;
    background-color: transparent;
}

.key-input-li {
    /* width: 100%; */
    height: auto;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 3px;
    opacity: 1;
    text-align: center;
    color: #707070;
    font-size: 16px;
    padding: 5px;
}

.key-input-ul {
    width: 100%;
    padding: 0px;
    list-style-type: none;
    display: flex;
    gap: 20px;
    /* margin: 8px; */
    flex-wrap: wrap;
}

.keyword-remove {
    width: 10px;
    height: 10px;
    margin-left: 9px;
    margin-bottom: 3px;
    cursor: pointer;
}

.continue-button-enabled {
    border-radius: 4px;
    /* margin-top: 10px; */
    background: var(--secondary-btn);
    border: var(--secondary-btn);
    color: white;
    font-weight: bold;
    /* height: 35px; */
    font-size: 14px;
    padding: 10px;
}


.continue-button-disabled {
    border-radius: 4px;
    /* margin-top: 10px; */
    background: #cdc9c7;
    color: #918e8d;
    font-weight: bold;
    /* height: 35px; */
    font-size: 14px;
    border: #cdc9c7;
    padding: 10px;
    pointer-events: none;
}

/* add-new-ui */
.add-ques-container {
    background-color: #fff;
}

.add-ques-header-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.add-ques-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.table-label {
    color: #600097;
}

.add-title-container {
    display: flex;
    justify-content: space-between;
    /* background-color: #918e8d; */

    width: 65%;
}

.add-question-btn {
    background-color: #600097;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    border: none;
    padding: 6px;
    /* width:130px; */
}

.add-question-btn:hover,
.add-btn-addques:hover {
    background-color: #39005a;
}

.save-question-btn {
    width: 70px;
}

.save-question-btn,
.generate-question-btn {
    background-color: #78d178;
    border-radius: 4px;
    color: white;
    font-size: 13px;
    border: none;
    padding: 6px;
}

.save-question-btn:hover,
.generate-question-btn:hover {
    background-color: var(--secondary-btn);
}

.add-new-questionnaire-conatiner {
    border: 1px solid #F0D4FF;
    background: #F8EBFF40 0% 0% no-repeat padding-box;

}

.continue-button {
    border-radius: 4px;
    /* margin-top: 27px; */
    background: var(--secondary-btn);
    border: var(--secondary-btn);
    color: white;
    font-weight: bold;
    height: 35px;
    font-size: 14px;
    padding: 15px;
}


.continue-button-disable {
    border-radius: 4px;
    /* margin-top: 27px; */
    background: #cdc9c7;
    color: #918e8d;
    font-weight: bold;
    height: 35px;
    font-size: 14px;
    border: #cdc9c7;
    padding: 15px;
}

.edit-button {
    width: 100px;
    border-radius: 5px;
    /* margin-top: 27px; */
    background: var(--secondary-btn);
    border: var(--secondary-btn);
    color: white;
    font-weight: bold;
    height: 40px;
    font-size: 15px;
    margin-left: -102px;
}


.edit-button-disable {
    width: 100px;
    border-radius: 5px;
    margin-left: -27px;
    background: #cdc9c7;
    color: #918e8d;
    font-weight: bold;
    height: 40px;
    font-size: 15px;
    border: #cdc9c7;
    margin-left: -102px;
}

.no-question-available {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* background-color: #707070; */
}

.no-ques-text {
    color: #A1A1A1;
    font-size: 14px;
}

.prompt-text-editor {
    border-radius: 4px;
    border: 1px solid #77DD77;
    color: #77DD77;
    background: #EFFFEF 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: space-between;
}

.send-icon {
    color: 1px solid #77DD77;
    font-size: 20px;
}

.send-icon:hover {
    cursor: pointer;
    color: var(--secondary-btn);
}

.add-question-footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.cancel-btn-addques,
.add-btn-addques {
    background: #E9E9E9 0% 0% no-repeat padding-box;
    border: 1px solid #C2C2C2;
    color: black;
    border-radius: 4px;
    font-size: 15px;
    padding: 5px;
    width: 70px;
}

.add-btn-addques {
    background-color: #600097;
    border: 1px solid #600097;
    color: white;
}

.cancel-btn-addques:hover {
    background-color: #d6d6d6;
}

.time-duration-add-ques {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #dfdfdf;
}

.add-ques-time {
    background: #dfdfdf;
    cursor: pointer;
    border-radius: 4px;
}

.add-ques-time-count {
    background: #eeeeee;
    font-size: 15px;

}

.addques-list {
    margin-top: 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}




.ques-number {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.ques-ans {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.ques-duration {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 40%;
}


.ques-details {
    display: flex;
    justify-content: space-between;
}

.arrow-container {
    border: 1px solid rgb(216, 216, 216);
    border-radius: 50px;
}

.arrow-container hr {
    margin: 0;
}

.arrow-container>button {
    border: none;
    background-color: transparent;
}

.question-content {
    font-size: 14px;

}

.question-content>button,
.question-content>ul>button {
    border: none;
    background-color: transparent;
    color: #600097;
    font-weight: bold;
}

.ques-duration div button {
    border: none;
    background-color: transparent;
}


.toggle-btn {
    pointer-events: none;
}


/* 
@media only screen and (min-width: 1500px) {
    .ques-duration {
        width: 50%;
    }
} */

.light-tooltip .tooltip-inner {
    background-color: #f8f9fa;
    /* Light background color */
    color: #000000;
    /* Text color */
    border-radius: 4px;
    border: 1px solid rgb(187, 187, 187);
}

.tooltip-arrow {
    display: none !important;
}