.serach-div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #F7F7F7;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    opacity: 1;
    display: flex;
    margin-top: 10px;
}

.search-label {
    color: white;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: var(--primary-color);
    opacity: 1;
    margin: 15px;
}


.filter-search {
    width: 200px;
    height: 30px;
    /* UI Properties */
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9E9 !important;
    border-radius: 4px;
    opacity: 1;
    margin: 10px;
    outline: none;
}

.filter-img{
    display: flex;
    justify-content: flex-start;
    margin: 7px;
}
